<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col cols="3">
          <p class="title">
            <v-progress-circular :size="36" :value="normalizedPositiveScore" color="cyan">
              {{ positiveScore }}
            </v-progress-circular> Échelle positive
          </p>
          <RatingList color="cyan" :scale="panns.positive.scales"/>
        </v-col>

        <v-col cols="3">
          <p class="title">
            <v-progress-circular :size="36" :value="normalizedNegativeScore" color="purple">
              {{ negativeScore }}
            </v-progress-circular> Échelle négative
          </p>
          <RatingList color="purple" :scale="panns.negative.scales"/>
        </v-col>

        <v-col cols="4">
          <p class="title">
            <v-progress-circular :size="36" :value="normalizedGeneralScore" color="blue">
              {{ generalScore }}
            </v-progress-circular> Échelle psychopathologique générale
          </p>
          <RatingList color="blue" :scale="panns.general.scales"/>
        </v-col>

        <v-col cols="2" class="flex-column text-center">
          <p class="title">Bilan</p>
          <div class="mb-3">
            <p class="subtitle mb-1">Scores</p>
            <v-progress-circular :size="70" :width="10" :value="normalizedScore" color="teal">
              {{ score }}
            </v-progress-circular>
          </div>
          <div class="mb-3">
            <p class="caption mb-0 text-left">Score positif</p>
            <v-progress-linear
              :value="normalizedPositiveScore" color="cyan" striped height="25" reactive>
              <template v-slot="{ value }">
                <strong>{{ Math.ceil(value) }}%</strong>
              </template>
            </v-progress-linear>
          </div>
          <div class="mb-3">
            <p class="caption mb-0 text-left">Score négatif</p>
            <v-progress-linear
              :value="normalizedNegativeScore" color="purple" dark striped height="25" reactive>
              <template v-slot="{ value }">
                <strong>{{ Math.ceil(value) }}%</strong>
              </template>
            </v-progress-linear>
          </div>
          <div class="mb-3">
            <p class="caption mb-0 text-left">Échelle. générale</p>
            <v-progress-linear
              :value="normalizedGeneralScore" color="blue" dark striped height="25" reactive>
              <template v-slot="{ value }">
                <strong>{{ Math.ceil(value) }}%</strong>
              </template>
            </v-progress-linear>
          </div>
          <div class="mt-5">
            <p class="subtitle mb-1">Diagnostic</p>
            <v-chip class="ma-2" color="teal" text-color="white">{{ diagnostic }}</v-chip>
          </div>
          <div class="mt-5">
            <p class="subtitle mb-1">Tendance</p>
            <v-chip class="ma-2" color="teal" text-color="white">
              <v-avatar left>
                <v-icon v-if="tendency === 'Positive'">mdi-plus</v-icon>
                <v-icon v-else>mdi-minus</v-icon>
              </v-avatar> {{ tendency }}
            </v-chip>
            <v-btn small class="mt-4" color="blue" @click="$router.go(-1)" dark text>
              <v-icon>mdi-arrow-left</v-icon> Retour
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import RatingList from '@/components/common/RatingList.vue';

export default {
  name: 'Test',
  components: { RatingList },
  data: () => ({
    panns: {
      positive: {
        scales: [
          { name: 'Idées délirantes', value: 1 },
          { name: 'Désorganisation conceptuelle', value: 1 },
          { name: 'Activité hallucinatoire', value: 1 },
          { name: 'Excitation', value: 1 },
          { name: 'Idée de grandeur', value: 1 },
          { name: 'Méfiance / Persécution', value: 1 },
          { name: 'Hostilité', value: 1 },
        ],
      },
      negative: {
        scales: [
          { name: 'Émoussement de l\'expression des émotions', value: 1 },
          { name: 'Retrait affectif', value: 1 },
          { name: 'Mauvais contact', value: 1 },
          { name: 'Repli social passif/apathique', value: 1 },
          { name: 'Difficultés d\'abstraction', value: 1 },
          { name: 'Absence de spontanéité et de fluidité dans la conversation', value: 1 },
          { name: 'Pensée stéréotypée', value: 1 },
        ],
      },
      general: {
        scales: [
          { name: 'Préoccupations somatiques', value: 1 },
          { name: 'Anxiété', value: 1 },
          { name: 'Sentiments de culpabilité', value: 1 },
          { name: 'Tension', value: 1 },
          { name: 'Maniérisme et trouble de la posture', value: 1 },
          { name: 'Dépression', value: 1 },
          { name: 'Ralentissement psychomoteur', value: 1 },
          { name: 'Manque de coopération', value: 1 },
          { name: 'Contenu inhabituel de la pensée', value: 1 },
          { name: 'Désorientation', value: 1 },
          { name: 'Manque d\'attention', value: 1 },
          { name: 'Manque de jugement et de prise de conscience de la maladie', value: 1 },
          { name: 'Trouble de la volition', value: 1 },
          { name: 'Mauvais contrôle pulsionnel', value: 1 },
          { name: 'Préoccupation excessive de soi (tendances autistiques)', value: 1 },
          { name: 'Évitement social actif', value: 1 },
        ],
      },
    },
  }),

  computed: {
    score() {
      return this.positiveScore + this.negativeScore + this.generalScore;
    },

    positiveScore() {
      return this.panns.positive.scales.reduce((total, item) => (total + item.value), 0);
    },

    negativeScore() {
      return this.panns.negative.scales.reduce((total, item) => (total + item.value), 0);
    },

    generalScore() {
      return this.panns.general.scales.reduce((total, item) => (total + item.value), 0);
    },

    normalizedScore() {
      // Maximum reachable score
      const max = 7 * (this.panns.positive.scales.length
      + this.panns.negative.scales.length
      + this.panns.general.scales.length);

      const normalized = (100 * this.score) / max;
      return normalized;
    },

    normalizedPositiveScore() {
      const normalizedPositiveScore = (100 * this.positiveScore)
      / (7 * this.panns.positive.scales.length);

      return normalizedPositiveScore;
    },

    normalizedNegativeScore() {
      const normalizedNegativeScore = (100 * this.negativeScore)
      / (7 * this.panns.negative.scales.length);

      return normalizedNegativeScore;
    },

    normalizedGeneralScore() {
      const normalizedGeneralScore = (100 * this.generalScore)
      / (7 * this.panns.general.scales.length);

      return normalizedGeneralScore;
    },

    tendency() {
      return (this.positiveScore - this.negativeScore > 0) ? 'Positive' : 'Négative';
    },

    diagnostic() {
      let result;

      if (this.score >= 40 && this.score <= 65) result = 'Légerement malade';
      else if (this.score >= 66 && this.score <= 85) result = 'Modérément malade';
      else if (this.score >= 86 && this.score <= 105) result = 'Notoirement malade';
      else if (this.score >= 106) result = 'Sévèrement malade';
      else result = 'Sain';
      return result;
    },
  },
};
</script>
