<template>
  <v-list dense>
    <v-list-item v-for="(item, i) in scale" :key="i">
      <v-list-item-content>
        <v-list-item-title>{{ item.name }} :
          <span class="`${color}--text text--darken-1`">{{ power[item.value-1] }}
            <span class="grey--text caption mr-2">({{ item.value }})</span>
          </span>
        </v-list-item-title>
        <v-rating v-model="item.value" length="7" size="15" color="`${color} ligthen-1`"
        background-color="`${color}`"
        hover dense empty-icon="mdi-checkbox-blank-circle-outline"
        full-icon="mdi-checkbox-blank-circle">
        </v-rating>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: 'RatingList',
  props: {
    scale: Array,
    color: String,
  },
  data: () => ({
    power: ['absente', 'minime', 'légère', 'moyenne', 'modéremment sévère', 'sévère', 'extrême'],
  }),
};
</script>
